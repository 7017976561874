import React, { useEffect } from "react";
import apxorIcon from "./apxor-icon.png";
import successIcon from "./success-icon.png";

import $ from "jquery";
import "bootstrap/dist/css/bootstrap.min.css";

export default function App() {
  useEffect(() => {
    const BASE_URL = "https://server.apxor.com/v1/external/test-devices/";
    $("#add-dialog").hide();
    $("#already-added-dialog").hide();
    $("#success-dialog").hide();

    window.onload = function () {
      $("#modalpopup").modal({
        backdrop: "static",
        keyboard: false,
      });
    };

    const query = new URLSearchParams(window.location.search);
    const token = query.get("token");
    const appId = query.get("appId");
    const deviceId = query.get("deviceId");
    const model = query.get("model");

    const testDeviceForm = $("#test-device-form");
    const addButton = $("#button-add");
    const okayButton = $("#button-okay");

    function validateTestDevice() {
      fetch(`${BASE_URL}validate?appId=${appId}&deviceId=${deviceId}`)
        .then((res) => res.json())
        .then((json) => {
          const isTestDevice = json.data.is_test_device;
          $("#loading-dialog").hide();
          if (isTestDevice) {
            // Show the Device ID and nickname
            $("#device-id").text(json.data.test_device.id);
            $("#device-nickname").text(json.data.test_device.nick_name);
            $("#already-added-dialog").show();
          } else {
            $("#add-dialog").show();
          }
        });
    }

    function verifyNickname(nickname) {
      addButton.css("background", "rgba(255, 127, 51, 0.65)");
      addButton.css("border", "rgba(255, 127, 51, 0.65)");
      addButton.prop("disabled", true);
      fetch(`${BASE_URL}nick-name/verify?appId=${appId}&nickName=${nickname}`)
        .then((res) => res.json())
        .then((json) => {
          const isTaken = json.data.taken;
          if (isTaken) {
            addButton.css("background", "rgba(255, 127, 51, 1)");
            addButton.css("border", "rgba(255, 127, 51, 1)");
            addButton.prop("disabled", false);
            $("#name-taken").show();
          } else {
            registerTestDevice(nickname);
          }
        });
    }

    function registerTestDevice(nickname) {
      addButton.css("background", "rgba(255, 127, 51, 0.65)");
      addButton.css("border", "rgba(255, 127, 51, 0.65)");
      try {
        fetch(`${BASE_URL}register?token=${token}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: deviceId,
            model: model,
            nick_name: nickname,
          }),
        })
          .then((res) => {
            if (res.ok) {
              return res.json();
            }
            return Promise.reject(res);
          })
          .then((json) => {
            if (json.status === "success") {
              $("#add-dialog").hide();
              $("#success-dialog").show();
            }
          })
          .catch((e) => {
            if (e.status === 401) {
              $("#name-taken").text(
                "The link is expired, please try with new one!"
              );
              $("#name-taken").show();
              $("#button-expiry").show();
              $("#button-add").hide();
            }
          });
      } catch (e) {
        console.log(e);
      }
    }

    const deviceName = $("#devicename");
    deviceName.on("keyup", () => {
      const val = deviceName.val();
      if (val.trim() === "") {
        addButton.css("background", "rgba(255, 127, 51, 0.65)");
        addButton.css("border", "rgba(255, 127, 51, 0.65)");
        addButton.prop("disabled", true);
      } else {
        addButton.css("background", "rgba(255, 127, 51, 1)");
        addButton.css("border", "rgba(255, 127, 51, 1)");
        addButton.prop("disabled", false);
      }
    });

    testDeviceForm.on("submit", (e) => {
      e.preventDefault();
      addButton.trigger("click");
    });

    addButton.on("click", () => {
      const nickname = deviceName.val();
      if (!nickname || nickname.trim() === "") {
        return;
      }

      verifyNickname(nickname.trim());
    });

    const addedFn = () => {
      window.location = "apxor://added";
    };

    $("#button-close").on("click", addedFn);
    okayButton.on("click", addedFn);

    const closeFn = () => {
      window.location = "apxor://close";
    };

    $("#close-icon").on("click", closeFn);
    $("#button-expiry").on("click", closeFn);

    validateTestDevice();
  }, []);

  return (
    <div className="container-fluid" style={{ marginTop: 10 }}>
      <div
        id="modalpopup"
        className="modal popupaligncenter in"
        role="dialog"
        aria-hidden="false"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          id="add-dialog"
          className="modal-dialog"
          role="document"
          style={{ width: "88vw" }}
        >
          <div
            className="modal-content"
            style={{
              WebkitBoxShadow: "0 3px 9px rgb(0 0 0 / 20%)",
              boxShadow: "0 3px 9px rgb(0 0 0 / 20%)",
              borderRadius: 8,
            }}
          >
            <div
              className="modal-header"
              style={{
                borderBottom: "none",
                paddingBottom: 0,
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <button
                id="close-icon"
                type="button"
                className="close"
                aria-label="Close"
                style={{
                  padding: 8,
                  color: "#a1adb6",
                  paddingTop: 0,
                  paddingBottom: 0,
                  opacity: 0.8,
                }}
              >
                <span
                  aria-hidden="true"
                  style={{ fontSize: 20, fontWeight: 500 }}
                >
                  x
                </span>
              </button>
            </div>
            <div>
              <img
                className="img-responsive center-block"
                src={apxorIcon}
                alt="Apxor Icon"
              />
              <br />
              <p
                style={{
                  fontFamily: "Manrope",
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: "22px",
                  letterSpacing: "-0.2px",
                  textAlign: "center",
                  marginBottom: 0,
                }}
              >
                Test Device Addition
              </p>
              <p
                style={{
                  fontFamily: "Manrope",
                  fontWeight: 500,
                  fontSize: 13,
                  lineHeight: "18px",
                  textAlign: "center",
                  color: "#667a8a",
                }}
              >
                Apxor wants to add your device as a test device
              </p>
            </div>
            <div className="modal-body">
              <form id="test-device-form">
                <div className="form-group">
                  <label
                    for="devicename"
                    style={{
                      color: "#667a8a",
                      fontFamily: "Manrope",
                      fontWeight: 500,
                      fontSize: 13,
                      lineHeight: "18px",
                      textAlign: "center",
                    }}
                  >
                    Device nickname
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="devicename"
                    placeholder="Give a unique name"
                  />
                  <p
                    id="name-taken"
                    style={{
                      display: "none",
                      color: "#f12d2d",
                      fontFamily: "Manrope",
                      fontSize: 11,
                      fontWeight: 600,
                      lineHeight: "15px",
                      letterSpacing: "0.01em",
                      textAlign: "left",
                    }}
                  >
                    This name is already taken
                  </p>
                </div>
              </form>
            </div>
            <p style={{ textAlign: "center", marginBottom: 24 }}>
              <button
                id="button-add"
                style={{
                  background: "rgba(255, 127, 51, 0.65)",
                  border: "rgba(255, 127, 51, 0.65)",
                  borderRadius: 2,
                  width: 100,
                  height: 38,
                  fontFamily: "Manrope",
                  fontSize: 13,
                  fontWeight: 700,
                  lineHeight: "18px",
                  letterSpacing: 0,
                  textAlign: "center",
                  color: "white",
                }}
                type="button"
                className="btn btn-secondary"
              >
                Add
              </button>
              <button
                id="button-expiry"
                style={{
                  display: "none",
                  background: "rgba(255, 127, 51)",
                  border: "rgba(255, 127, 51)",
                  borderRadius: 2,
                  width: 100,
                  height: 38,
                  fontFamily: "Manrope",
                  fontSize: 13,
                  fontWeight: 700,
                  lineHeight: "18px",
                  letterSpacing: 0,
                  textAlign: "center",
                }}
                type="button"
                className="btn btn-secondary"
              >
                Close
              </button>
            </p>
          </div>
        </div>
        <div
          id="already-added-dialog"
          className="modal-dialog"
          role="document"
          style={{ width: "88vw" }}
        >
          <div
            className="modal-content"
            style={{
              WebkitBoxShadow: "0 3px 9px rgb(0 0 0 / 20%)",
              boxShadow: "0 3px 9px rgb(0 0 0 / 20%)",
              borderRadius: 8,
            }}
          >
            <div style={{ marginTop: 24 }}>
              <img
                className="img-responsive center-block"
                src={apxorIcon}
                alt="Apxor Icon"
              />
              <br />
              <p
                style={{
                  fontFamily: "Manrope",
                  fontSize: 16,
                  fontWeight: 700,
                  lineHeight: "22px",
                  letterSpacing: "-0.20000000298023224px",
                  textAlign: "center",
                }}
              >
                Your device has already been added
              </p>
            </div>
            <div className="modal-body" style={{ paddingLeft: "13%" }}>
              <p
                style={{
                  color: "#667a8a",
                  fontFamily: "Manrope",
                  fontWeight: 600,
                  fontSize: 13,
                  marginBottom: 2,
                }}
              >
                <span
                  style={{
                    color: "#667a8a",
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: 13,
                  }}
                >
                  Device ID:
                </span>
                <span
                  id="device-id"
                  style={{
                    color: "#002845",
                    fontFamily: "Manrope",
                    fontSize: 13,
                    fontWeight: 600,
                    lineHeight: "18px",
                    letterSpacing: 0,
                    textAlign: "left",
                    marginLeft: 6,
                  }}
                ></span>
              </p>
              <p
                style={{
                  color: "#667a8a",
                  fontFamily: "Manrope",
                  fontWeight: 600,
                  fontSize: 13,
                }}
              >
                <span
                  style={{
                    color: "#667a8a",
                    fontFamily: "Manrope",
                    fontWeight: 500,
                    fontSize: 13,
                  }}
                >
                  Device nickname:
                </span>
                <span
                  id="device-nickname"
                  style={{
                    color: "#002845",
                    fontFamily: "Manrope",
                    fontSize: 13,
                    fontWeight: 600,
                    lineHeight: "18px",
                    letterSpacing: 0,
                    textAlign: "left",
                    marginLeft: 6,
                  }}
                ></span>
              </p>
            </div>
            <p style={{ textAlign: "center", marginBottom: 24 }}>
              <button
                id="button-okay"
                style={{
                  background: "rgba(255, 127, 51)",
                  border: "rgba(255, 127, 51)",
                  borderRadius: 2,
                  width: 100,
                  height: 38,
                  fontFamily: "Manrope",
                  fontSize: 13,
                  fontWeight: 700,
                  lineHeight: "18px",
                  letterSpacing: 0,
                  textAlign: "center",
                }}
                type="button"
                className="btn btn-secondary"
              >
                Okay
              </button>
            </p>
          </div>
        </div>
        <div
          id="success-dialog"
          className="modal-dialog"
          role="document"
          style={{ width: "88vw" }}
        >
          <div
            className="modal-content"
            style={{
              WebkitBoxShadow: "0 3px 9px rgb(0 0 0 / 20%)",
              boxShadow: "0 3px 9px rgb(0 0 0 / 20%)",
              borderRadius: 8,
            }}
          >
            <div style={{ marginTop: 24, marginBottom: 24 }}>
              <img
                className="img-responsive center-block"
                src={successIcon}
                alt="Apxor Icon"
              />
              <br />
              <p
                style={{
                  fontFamily: "Manrope",
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: "22px",
                  letterSpacing: "-0.2px",
                  textAlign: "center",
                }}
              >
                Test Device Added Successfully
              </p>
              <p
                style={{ textAlign: "center", marginBottom: -4, marginTop: 16 }}
              >
                <button
                  id="button-close"
                  style={{
                    background: "rgba(255, 127, 51)",
                    border: "rgba(255, 127, 51)",
                    borderRadius: 2,
                    width: 100,
                    height: 38,
                    fontFamily: "Manrope",
                    fontSize: 13,
                    fontWeight: 700,
                    lineHeight: "18px",
                    letterSpacing: 0,
                    textAlign: "center",
                  }}
                  type="button"
                  className="btn btn-secondary"
                >
                  Close
                </button>
              </p>
            </div>
          </div>
        </div>
        <div
          id="loading-dialog"
          className="modal-dialog"
          role="document"
          style={{ width: "88vw" }}
        >
          <div
            id="modal-content"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="fa fa-spinner fa-5x fa-spin"
              style={{ color: "#202020" }}
            ></i>
          </div>
        </div>
      </div>
    </div>
  );
}
